import React, { Component } from "react";
import "./application.css";
import { Progress } from "antd";
import ParentInfo from "./ApplicationFlow/ParentInfo";
import { connect } from "react-redux";
import SchoolInfo from "./ApplicationFlow/SchoolInfo";
class ApplicationForm extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <div id="application-form" className="application">
        <div className="application-container">
          <div className="application-inner">
            <div className="application-header">
              <div className="header-content"></div>
            </div>
            <div className="application-steps">
              <div className="steps-header">
                <p className="pages">خطوة 1 من 4</p>
                <div className="stepper-timeline">
                  <p className="stepper-stage">الطلب: بيانات ولي الأمر</p>
                  <div className="steppers-bars">
                    <Progress
                      strokeLinecap="butt"
                      percent={100}
                      strokeColor={"#000091"}
                      showInfo={false}
                    />
                    {/* <Progress
                      strokeLinecap="butt"
                      percent={0}
                      showInfo={false}
                    /> */}
                    <Progress
                      strokeLinecap="butt"
                      percent={0}
                      showInfo={false}
                    />
                    <Progress
                      strokeLinecap="butt"
                      percent={0}
                      showInfo={false}
                    />
                  </div>
                  <div className="stepper-desc">
                    <p>الخطوة القادمة : بيـانات المدرسة </p>
                  </div>
                  {this.props.currentIndex===0 &&(
                    <ParentInfo/>
                  )}
                   {this.props.currentIndex===1 &&(
                    <SchoolInfo/>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    currentIndex: state.installmentReducer.stepIndex,
    parentInfo: state.installmentReducer.parentInfo,
  };
};
export default connect(mapStateToProps, null)(ApplicationForm);


