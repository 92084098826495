import React, { useState } from "react";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/custom-animations/cube-animation.css";
import "react-awesome-slider/dist/custom-animations/fold-out-animation.css";
import "react-awesome-slider/dist/custom-animations/open-animation.css";
import "react-awesome-slider/dist/custom-animations/fall-animation.css";
import "react-awesome-slider/dist/custom-animations/scale-out-animation.css";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import slide1 from "../../assets/slider/slide1.jpg";
import slide2 from "../../assets/slider/slide2.jpg";
import slide3 from "../../assets/slider/slide3.jpg";
import "./auth.css";
import { Form, Input, Divider, Button } from "antd";
import { FcGoogle } from "react-icons/fc";
import { BsFacebook, BsTwitterX } from "react-icons/bs";
const AutoplaySlider = withAutoplay(AwesomeSlider);

const Signup = (props) => {
  const onFinish = (values) => {
    console.log(values);
  };
  return (
    <div className="auth-page signup-page relative">
      <div className="sliderr-wrapper">
        <AutoplaySlider play={true} animation="cubeAnimation" interval={20000}>
          <div
            className="slider-slide"
            style={{
              backgroundImage: `url("${slide1}")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
          <div
            className="slider-slide"
            style={{
              backgroundImage: `url("${slide2}")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
          <div
            className="slider-slide"
            style={{
              backgroundImage: `url("${slide3}")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
        </AutoplaySlider>
      </div>
      <div className="auth-form absolute">
        <Form onFinish={onFinish} size="large" layout="vertical">
          <Form.Item name="name" label="الإسم كاملا">
            <Input type="text" placeholder="اسم ولي الأمر كاملا" />
          </Form.Item>
          <Form.Item name="email" label="البريد الالكتروني">
            <Input type="email" placeholder="البريد الالكتروني" />
          </Form.Item>
          <Form.Item name="password" label="كلمة المرور">
            <Input type="password" placeholder="كلمةالمرور" />
          </Form.Item>
          <Form.Item name="password_repeat" label="إعاده كلمة المرور">
            <Input type="password" placeholder="أعد كتابة كلمة المرور" />
          </Form.Item>
          <Form.Item>
            <Button className="authenticate-btn">إنشاء حساب </Button>
          </Form.Item>
          <Divider>أو بواسطة</Divider>
          <div className="auth-btns">
            <button>
              <FcGoogle />{" "}
            </button>
            <button>
              <BsFacebook />
            </button>
            <button>
              <BsTwitterX />
            </button>
          </div>
          <div className="form-tail">
            <p>
              لديك حساب بالفعل ؟
              <b>
                <a href="/login">تسجيل دخول</a>
              </b>
            </p>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Signup;
