// import * as actions from "../constants";
// // import { auth, googleProvider, facebookProvider } from './../../firebase';
// const installmentState = {
//   stepIndex: 0,
//   stepTitle: "basic-info",
//   children: [],
//   parentInfo: null,
// };

// const flowInfo =
//   JSON.parse(localStorage.getItem("instate")) ?? installmentState;
// export const installmentReducer = (state = flowInfo, action) => {
//   switch (action.type) {
//     case actions.ADD_BASIC_INFO_STEP:
//       return {
//         ...state,
//         parentInfo: action.payload,
//         stepIndex: 1,
//       };

//     case actions.ADD_CHILDREN_INFO_STEP:
//       return {
//         ...state,
//         children: action.payload,
//         stepIndex: 2,
//       };
//       case actions.GO_TO_STEP:
//         return {
//           ...state,
//           stepIndex: action.payload,
//         };

//       case actions.ADD_TAQSEET_DOCUMENT_STEO:
//       return {
//         ...state,
//         // children: action.payload,
//         stepIndex: 3,
//       };
//     // case actions.LOGOUT:
//       return {
//         ...state,
//         user: null,
//         isLoggedIn: false,
//         info: null,
//       };
//     default:
//       return state;
//   }
// };

import * as actions from "../constants";
// import { auth, googleProvider, facebookProvider } from './../../firebase';
const installmentState = {
  stepIndex: 0,
  stepTitle: "PARENT_INFO",
  children: [],
  parentInfo: null,
};

const flowInfo =
  JSON.parse(localStorage.getItem("appstate")) ?? installmentState;
export const installmentReducer = (state = flowInfo, action) => {
  switch (action.type) {
    case actions.ADD_PARENT_INFO_STEP:
      return {
        ...state,
        parentInfo: action.payload,
        stepIndex: 1,
      };

    case actions.ADD_SCHOOL_INFO_STEP:
      return {
        ...state,
        children: action.payload,
        stepIndex: 2,
      };
    case actions.ADD_CHILDREN_INFO_STEP:
      return {
        ...state,
        children: action.payload,
        stepIndex: 3,
      };
    case actions.UPLOAD_PARENT_DOCUMENTS_STEP:
      return {
        ...state,
        children: action.payload,
        stepIndex: 4,
      };
    case actions.GO_TO_STEP:
      return {
        ...state,
        stepIndex: action.payload,
      };
    default:
      return state;
  }
};
