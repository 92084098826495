import { Component } from "react";
import "./finalhome.css";
// import hero_video from "../../assets/video.mp4";
import wedding from "../../assets/sections/wedding-halles1.jpg";
import university from "../../assets/university.jpg";
import school from "../../assets/sections/school1.jpg";
import nursary from "../../assets/sections/nursary1.jpg";
class FinalHomepage extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <div className="final-homepage">
        <div className="hero-section">
          <video autoPlay loop muted playsInline className="video">
            <source
              src="https://res.cloudinary.com/dhy5o5vdh/video/upload/v1731359306/video_x7is1x.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
          <div className="hero-desc">
            <p className="main">تعليم بـلا حدود، بخيارات دفع مرنـة</p>
            <p className="sec">
              تقدم بطلب تقسيط لأشهر الجامعات الحكومية والخاصة، المدارس والحضانات
            </p>
            <button className="now-btn">ابدأ الآن</button>
          </div>
        </div>
        <div className="website-sections-outer">
          <div className="inner-wrapper">
            <div className="sections">
              <div
                className="app-section"
                style={{
                  backgroundImage: `url("${wedding}")`,
                }}
              >
                <div className="secion-text">
                  <p>قاعـات أفراح </p>
                  <button>اعرف المزيد</button>
                </div>
              </div>
              <div
                className="app-section"
                style={{
                  backgroundImage: `url("${university}")`,
                }}
              >
                <div className="secion-text">
                  <p> جامعــات </p>
                  <button>اعرف المزيد</button>
                </div>
              </div>
              <div
                className="app-section"
                style={{
                  backgroundImage: `url("${school}")`,
                }}
              >
                <div className="secion-text">
                  <p> مــدارس </p>
                  <button>اعرف المزيد</button>
                </div>
              </div>
              <div
                className="app-section"
                style={{
                  backgroundImage: `url("${nursary}")`,
                }}
              >
                <div className="secion-text">
                  <p> حضــانات </p>
                  <button>اعرف المزيد</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FinalHomepage;
