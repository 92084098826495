import { Input } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { setParentInfo } from "../../redux/actions/authActions";

class ParentInfo extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <div className="application-form-blocks">
        <div className="blocks-inner">
          <div className="form-block">
            <p className="form-block-name absolute">بيانات أساسية</p>
            <div className="form-fields">
              <div className="field">
                <div className="cform-label">اسم ولي الأمر كـاملا </div>
                <Input size="large" placeholder="حقل بيـنات" />
              </div>

              <div className="field">
                <div className="cform-label"> رقم الهوية الشخصية</div>
                <Input size="large" placeholder="حقل بيـنات" />
              </div>
            </div>
          </div>
          <div className="form-block">
            <p className="form-block-name absolute">بيانات التواصل</p>
            <div className="form-fields">
              <div className="field">
                <div className="cform-label">عنوان الإقامة</div>
                <Input size="large" placeholder="حقل بيـنات" />
              </div>

              <div className="field">
                <div className="cform-label"> البريد الالكتروني</div>
                <Input size="large" placeholder="حقل بيـنات" />
              </div>
              <div className="field">
                <div className="cform-label"> رقم الجوال</div>
                <Input size="large" placeholder="حقل بيـنات" />
              </div>
            </div>
          </div>
          <div className="form-block">
            <p className="form-block-name absolute">بيانات إضافية</p>
            <div className="form-fields">
              <div className="field">
                <div className="cform-label"> المهنة</div>
                <Input size="large" placeholder="حقل بيـنات" />
              </div>
            </div>
          </div>
          <button className="next-flow"
          onClick={()=>{
            this.props.setParentInfo({
                name:"",
                phone:""
            })
          }}
          >التالي</button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setParentInfo: (info) => dispatch(setParentInfo(info)),
});
const mapStateToProps = (state) => {
  return {
    currentIndex: state.installmentReducer.stepIndex,
    parentInfo: state.installmentReducer.parentInfo,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ParentInfo);
